import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: 'quiz',
  data() {
    return {
      id: null,
      quiz: null,
      questions: null,
      passed: false
    }
  },
  computed: {
    ...mapGetters(['getQuizzes', 'getOrderTarget']),
    wrongOrNoAnswers() {
      if (!this.questions) return true
      return this.questions.filter(q => q.answer === null).length > 0
    }
  },
  mounted() {
    const { id } = this.$route.query
    this.id = id
    this.quiz = this.getQuizzes[this.id]
    this.questions = [...this.quiz.questions.map(question => { return { ...question, answer: null } })]
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['updateQuestionIncorrectAnswer']),
    radioButtonClick(question, answer) {
      this.questions = this.questions.map(q => {
        return {
          ...q,
          answer: q.questionId === question.questionId ? answer : q.answer
        }
      })
    },
    submitBtnClick() {
      if (this.passed) {
        if (this.id === 'q1') this.$router.push('/adjustment-details')
        if (this.id === 'q2') {
          this.$router.push(`/task?heading=${encodeURI("Work Period 1")}&id=w1&targetOrders=${this.getOrderTarget}`)
        } 
      } else {
        this.submitTest()
      }
    },
    submitTest() {
      let incorrectAnswer = false
      this.questions.map(question => {
        if (question.correctAnswers.some(answer => answer === question.answer)) {
          question.answeredCorrect = true
        } else {
          incorrectAnswer = true
          question.answeredCorrect = false
          question.answer = null
          this.updateQuestionIncorrectAnswer({quizId: this.id, questionId: question.questionId})
        }
        return question
      })
      this.passed = !incorrectAnswer
    }
  }
}